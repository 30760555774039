import React, { useMemo } from 'react';


const ResponsiveImg = ({ aspectRatio, src, alt }) => {
  const style = useMemo(() => ({
    paddingTop: `${100 / aspectRatio}%`
  }), [ aspectRatio ]);

  return (
    <div className="responsiveImg" style={style}>
      <img src={src} alt={alt || ''} />
    </div>
  );
}

export default ResponsiveImg;
