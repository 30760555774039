import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../components/SEO';
import Header from '../components/Header';
import ContentRow from '../components/ContentRow';
import ResponsiveImg from '../components/ResponsiveImg';
import { openFindNeosurf, commonHeaderColor } from '../util';


const PaysafeCardPage = ({ data }) => (
  <>
    <SEO
      title="Neosurf voucher replaces Paysafe Card - Secure Deposits"
      description="Buy Neosurf voucher - a new Paysafe Card replacement. Safe &amp; secure deposits to gambling &amp; e2ntertainment websites."
      alternateLanguagePages={[
        {
          path: 'paysafecard',
          language: 'en'
        },
        {
          path: 'paysafecard',
          language: 'fr'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://www.betbtc.io/sign-up/a48acf12" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.paysafeCard.childImageSharp.fluid} alt="Buy Neosurf &amp; pay securely online" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="smaller h3 smaller">NeoSurf now replaces Paysafe Card and works perfectly in Australia and Worldwide!</h1>
            <p className="textColorDark">Have you experienced problems depositing with Paysafe Card lately? Are you from Australia and Paysafe transactions are no longer possible due to new laws and legislation?</p>
            <p className="textColorAccent bigger zeroMarginT"><em>Luckily, there is a solution!</em></p>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <div className="grid c-2">
        <div>
          <div className="contentBox">
            <h2 className="smaller">NEOSURF HIGHLIGHTS</h2>
            <ul>
              <li className="textColorDark">Exactly The same Voucher System as Paysafe Card</li>
              <li className="textColorDark">Safe &amp; Secure For Internet Transactions</li>
              <li className="textColorDark">Buy Neosurf in over 135,000 Locations Worldwide</li>
            </ul>
          </div>
        </div>
        <div>
          <Img fluid={data.neosurf.childImageSharp.fluid} alt="Neosurf codes available in 135000 stores worldwide" />
        </div>
      </div>
    </ContentRow>
    <ContentRow
      backgroundColor="rgba(255,0,155, 0.8)"
      Img={
        <ResponsiveImg
          aspectRatio={data.findNeosurf.childImageSharp.fixed.aspectRatio}
          src={data.findNeosurf.childImageSharp.fixed.src}
          alt=""
        />
      }
      padV
    >
      <div className="contentBox textAlignCenter">
        <h2 className="smaller textColorComplementary">Find Neosurf Retailer Near You</h2>
        <p className="textColorLight">Customers like Neosurf cash vouchers as they are available from thousands of convenient locations around the world. Today you will find Neosurf cash vouchers in <strong className="textColorComplementary">convenience stores, supermarkets, gas stations, newsstands, tobacco shops, kiosks, internet cafés and more</strong>.</p>
        <button className="button big" onClick={openFindNeosurf}>Where to find Neosurf?</button>
      </div>
    </ContentRow>
    <ContentRow backgroundColor="#eee">
      <div className="grid c-2">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">Pay &amp; Play Online with Neosurf</h2>
            <p className="textColorDark">Don't want to use debit/credit cards to pay online? Or, you don't even have one? You might not want to provide sensitive banking details as privacy is your main concern?</p>
            <p>Neosurf may be the <strong>perfect solution for you</strong>! Buy a Neosurf voucher for cash and pay &amp; play online safely. Simple as that! Watch a short video explaining how Neosurf voucher system works.</p>
          </div>
        </div>
        <div>
          <div className="videoWrapper">
            <iframe
              title="neosurfVideo"
              src="https://www.youtube.com/embed/ZZKglPJVhFE"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            >
            </iframe>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <h2 className="smaller textAlignCenter textUppercase">Already bought a voucher?<br />Get back to the cashier and make a deposit!</h2>
      <div className="grid c-4 bigGap">
        <div>
          <div className="contentBox">
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648">
              <Img fluid={data.luckystar.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.betbtc.io/sign-up/a48acf12">
              <Img fluid={data.betbtc.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="http://www.spacelilly.com/?a_aid=5a6fb263dabe6&amp;a_bid=cd4655e5">
              <Img fluid={data.lilly.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.777slotsbay.com/?goft=355fce1f-1701-4bc8-b29d-e3e0ec292198">
              <Img fluid={data.sevenSB.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="http://www.777bay.com/?a_aid=5a6fb263dabe6">
              <Img fluid={data.sevenB.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default PaysafeCardPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    paysafeCard: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "paysafecard/mac1.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 600,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    neosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "paysafecard/neosurf2_2.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    findNeosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "bg2.jpg"}) {
      childImageSharp {
        fixed(width: 524) {
          aspectRatio
          src
        }
      }
    }
    luckystar: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/lucky-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 328,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    betbtc: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/betbtc-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 328,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lilly: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/lilly-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    emu: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/emucasino-logo-bw.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sevenB: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/777bay-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sevenSB: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/777slotsbay-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
